import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _12a74e48 = () => interopDefault(import('..\\pages\\catalog\\index.vue' /* webpackChunkName: "pages/catalog/index" */))
const _03cd4d28 = () => interopDefault(import('..\\pages\\customer-credits\\index.vue' /* webpackChunkName: "pages/customer-credits/index" */))
const _a292891a = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages/index" */))
const _785e5e8a = () => interopDefault(import('..\\pages\\login.vue' /* webpackChunkName: "pages/login" */))
const _3f03098e = () => interopDefault(import('..\\pages\\logout.vue' /* webpackChunkName: "pages/logout" */))
const _6a5366eb = () => interopDefault(import('..\\pages\\my-profile\\index.vue' /* webpackChunkName: "pages/my-profile/index" */))
const _777fa042 = () => interopDefault(import('..\\pages\\new-sale\\index.vue' /* webpackChunkName: "pages/new-sale/index" */))
const _6d02d2ee = () => interopDefault(import('..\\pages\\orders\\index.vue' /* webpackChunkName: "pages/orders/index" */))
const _7da96934 = () => interopDefault(import('..\\pages\\payments\\index.vue' /* webpackChunkName: "pages/payments/index" */))
const _70620630 = () => interopDefault(import('..\\pages\\register\\index.vue' /* webpackChunkName: "pages/register/index" */))
const _e4ae0e3c = () => interopDefault(import('..\\pages\\reports\\index.vue' /* webpackChunkName: "pages/reports/index" */))
const _5e6815ce = () => interopDefault(import('..\\pages\\wishlist\\index.vue' /* webpackChunkName: "pages/wishlist/index" */))
const _29c0d57c = () => interopDefault(import('..\\pages\\cms\\components\\index.vue' /* webpackChunkName: "pages/cms/components/index" */))
const _7545463e = () => interopDefault(import('..\\pages\\cms\\contents\\index.vue' /* webpackChunkName: "pages/cms/contents/index" */))
const _3c9ae70a = () => interopDefault(import('..\\pages\\configuration\\admin-settings\\index.vue' /* webpackChunkName: "pages/configuration/admin-settings/index" */))
const _5788a84c = () => interopDefault(import('..\\pages\\configuration\\integrations\\index.vue' /* webpackChunkName: "pages/configuration/integrations/index" */))
const _1f653069 = () => interopDefault(import('..\\pages\\customer-credits\\create-new.vue' /* webpackChunkName: "pages/customer-credits/create-new" */))
const _0e4497bb = () => interopDefault(import('..\\pages\\register\\transactions\\index.vue' /* webpackChunkName: "pages/register/transactions/index" */))
const _9b8d0794 = () => interopDefault(import('..\\pages\\reports\\customer-balances.vue' /* webpackChunkName: "pages/reports/customer-balances" */))
const _85d5577c = () => interopDefault(import('..\\pages\\reports\\customer-statements-dialog.vue' /* webpackChunkName: "pages/reports/customer-statements-dialog" */))
const _47758510 = () => interopDefault(import('..\\pages\\reports\\events-report.vue' /* webpackChunkName: "pages/reports/events-report" */))
const _6276eb70 = () => interopDefault(import('..\\pages\\reports\\layaways-report.vue' /* webpackChunkName: "pages/reports/layaways-report" */))
const _da85d7e4 = () => interopDefault(import('..\\pages\\reports\\sales-reports.vue' /* webpackChunkName: "pages/reports/sales-reports" */))
const _30c45379 = () => interopDefault(import('..\\pages\\reports\\wishlist-items-report.vue' /* webpackChunkName: "pages/reports/wishlist-items-report" */))
const _76edf5a3 = () => interopDefault(import('..\\pages\\services\\custom-order\\index.vue' /* webpackChunkName: "pages/services/custom-order/index" */))
const _7b9ee4d8 = () => interopDefault(import('..\\pages\\services\\layaways\\index.vue' /* webpackChunkName: "pages/services/layaways/index" */))
const _1a95ceaa = () => interopDefault(import('..\\pages\\services\\repair-center\\index.vue' /* webpackChunkName: "pages/services/repair-center/index" */))
const _f2e3497e = () => interopDefault(import('..\\pages\\services\\custom-order\\create-new-custom-order.vue' /* webpackChunkName: "pages/services/custom-order/create-new-custom-order" */))
const _e755ac7c = () => interopDefault(import('..\\pages\\services\\layaways\\create-new-layaway.vue' /* webpackChunkName: "pages/services/layaways/create-new-layaway" */))
const _54b89eb4 = () => interopDefault(import('..\\pages\\services\\repair-center\\create-new-repair-ticket.vue' /* webpackChunkName: "pages/services/repair-center/create-new-repair-ticket" */))
const _4304d179 = () => interopDefault(import('..\\pages\\cms\\components\\_componentId.vue' /* webpackChunkName: "pages/cms/components/_componentId" */))
const _569a628e = () => interopDefault(import('..\\pages\\cms\\contents\\_contentId.vue' /* webpackChunkName: "pages/cms/contents/_contentId" */))
const _77b1166e = () => interopDefault(import('..\\pages\\catalog\\_id\\products\\index.vue' /* webpackChunkName: "pages/catalog/_id/products/index" */))
const _4622bd22 = () => interopDefault(import('..\\pages\\product\\_id\\_slug\\index.vue' /* webpackChunkName: "pages/product/_id/_slug/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/catalog",
    component: _12a74e48,
    name: "catalog___en"
  }, {
    path: "/customer-credits",
    component: _03cd4d28,
    name: "customer-credits___en"
  }, {
    path: "/fr",
    component: _a292891a,
    name: "index___fr"
  }, {
    path: "/login",
    component: _785e5e8a,
    name: "login___en"
  }, {
    path: "/logout",
    component: _3f03098e,
    name: "logout___en"
  }, {
    path: "/my-profile",
    component: _6a5366eb,
    name: "my-profile___en"
  }, {
    path: "/new-sale",
    component: _777fa042,
    name: "new-sale___en"
  }, {
    path: "/orders",
    component: _6d02d2ee,
    name: "orders___en"
  }, {
    path: "/payments",
    component: _7da96934,
    name: "payments___en"
  }, {
    path: "/register",
    component: _70620630,
    name: "register___en"
  }, {
    path: "/reports",
    component: _e4ae0e3c,
    name: "reports___en"
  }, {
    path: "/wishlist",
    component: _5e6815ce,
    name: "wishlist___en"
  }, {
    path: "/cms/components",
    component: _29c0d57c,
    name: "cms-components___en"
  }, {
    path: "/cms/contents",
    component: _7545463e,
    name: "cms-contents___en"
  }, {
    path: "/configuration/admin-settings",
    component: _3c9ae70a,
    name: "configuration-admin-settings___en"
  }, {
    path: "/configuration/integrations",
    component: _5788a84c,
    name: "configuration-integrations___en"
  }, {
    path: "/customer-credits/create-new",
    component: _1f653069,
    name: "customer-credits-create-new___en"
  }, {
    path: "/fr/catalog",
    component: _12a74e48,
    name: "catalog___fr"
  }, {
    path: "/fr/customer-credits",
    component: _03cd4d28,
    name: "customer-credits___fr"
  }, {
    path: "/fr/login",
    component: _785e5e8a,
    name: "login___fr"
  }, {
    path: "/fr/logout",
    component: _3f03098e,
    name: "logout___fr"
  }, {
    path: "/fr/my-profile",
    component: _6a5366eb,
    name: "my-profile___fr"
  }, {
    path: "/fr/new-sale",
    component: _777fa042,
    name: "new-sale___fr"
  }, {
    path: "/fr/orders",
    component: _6d02d2ee,
    name: "orders___fr"
  }, {
    path: "/fr/payments",
    component: _7da96934,
    name: "payments___fr"
  }, {
    path: "/fr/register",
    component: _70620630,
    name: "register___fr"
  }, {
    path: "/fr/reports",
    component: _e4ae0e3c,
    name: "reports___fr"
  }, {
    path: "/fr/wishlist",
    component: _5e6815ce,
    name: "wishlist___fr"
  }, {
    path: "/register/transactions",
    component: _0e4497bb,
    name: "register-transactions___en"
  }, {
    path: "/reports/customer-balances",
    component: _9b8d0794,
    name: "reports-customer-balances___en"
  }, {
    path: "/reports/customer-statements-dialog",
    component: _85d5577c,
    name: "reports-customer-statements-dialog___en"
  }, {
    path: "/reports/events-report",
    component: _47758510,
    name: "reports-events-report___en"
  }, {
    path: "/reports/layaways-report",
    component: _6276eb70,
    name: "reports-layaways-report___en"
  }, {
    path: "/reports/sales-reports",
    component: _da85d7e4,
    name: "reports-sales-reports___en"
  }, {
    path: "/reports/wishlist-items-report",
    component: _30c45379,
    name: "reports-wishlist-items-report___en"
  }, {
    path: "/services/custom-order",
    component: _76edf5a3,
    name: "services-custom-order___en"
  }, {
    path: "/services/layaways",
    component: _7b9ee4d8,
    name: "services-layaways___en"
  }, {
    path: "/services/repair-center",
    component: _1a95ceaa,
    name: "services-repair-center___en"
  }, {
    path: "/fr/cms/components",
    component: _29c0d57c,
    name: "cms-components___fr"
  }, {
    path: "/fr/cms/contents",
    component: _7545463e,
    name: "cms-contents___fr"
  }, {
    path: "/fr/configuration/admin-settings",
    component: _3c9ae70a,
    name: "configuration-admin-settings___fr"
  }, {
    path: "/fr/configuration/integrations",
    component: _5788a84c,
    name: "configuration-integrations___fr"
  }, {
    path: "/fr/customer-credits/create-new",
    component: _1f653069,
    name: "customer-credits-create-new___fr"
  }, {
    path: "/fr/register/transactions",
    component: _0e4497bb,
    name: "register-transactions___fr"
  }, {
    path: "/fr/reports/customer-balances",
    component: _9b8d0794,
    name: "reports-customer-balances___fr"
  }, {
    path: "/fr/reports/customer-statements-dialog",
    component: _85d5577c,
    name: "reports-customer-statements-dialog___fr"
  }, {
    path: "/fr/reports/events-report",
    component: _47758510,
    name: "reports-events-report___fr"
  }, {
    path: "/fr/reports/layaways-report",
    component: _6276eb70,
    name: "reports-layaways-report___fr"
  }, {
    path: "/fr/reports/sales-reports",
    component: _da85d7e4,
    name: "reports-sales-reports___fr"
  }, {
    path: "/fr/reports/wishlist-items-report",
    component: _30c45379,
    name: "reports-wishlist-items-report___fr"
  }, {
    path: "/fr/services/custom-order",
    component: _76edf5a3,
    name: "services-custom-order___fr"
  }, {
    path: "/fr/services/layaways",
    component: _7b9ee4d8,
    name: "services-layaways___fr"
  }, {
    path: "/fr/services/repair-center",
    component: _1a95ceaa,
    name: "services-repair-center___fr"
  }, {
    path: "/services/custom-order/create-new-custom-order",
    component: _f2e3497e,
    name: "services-custom-order-create-new-custom-order___en"
  }, {
    path: "/services/layaways/create-new-layaway",
    component: _e755ac7c,
    name: "services-layaways-create-new-layaway___en"
  }, {
    path: "/services/repair-center/create-new-repair-ticket",
    component: _54b89eb4,
    name: "services-repair-center-create-new-repair-ticket___en"
  }, {
    path: "/fr/services/custom-order/create-new-custom-order",
    component: _f2e3497e,
    name: "services-custom-order-create-new-custom-order___fr"
  }, {
    path: "/fr/services/layaways/create-new-layaway",
    component: _e755ac7c,
    name: "services-layaways-create-new-layaway___fr"
  }, {
    path: "/fr/services/repair-center/create-new-repair-ticket",
    component: _54b89eb4,
    name: "services-repair-center-create-new-repair-ticket___fr"
  }, {
    path: "/fr/cms/components/:componentId",
    component: _4304d179,
    name: "cms-components-componentId___fr"
  }, {
    path: "/fr/cms/contents/:contentId",
    component: _569a628e,
    name: "cms-contents-contentId___fr"
  }, {
    path: "/cms/components/:componentId",
    component: _4304d179,
    name: "cms-components-componentId___en"
  }, {
    path: "/cms/contents/:contentId",
    component: _569a628e,
    name: "cms-contents-contentId___en"
  }, {
    path: "/fr/catalog/:id/products",
    component: _77b1166e,
    name: "catalog-id-products___fr"
  }, {
    path: "/fr/product/:id?/:slug",
    component: _4622bd22,
    name: "product-id-slug___fr"
  }, {
    path: "/catalog/:id/products",
    component: _77b1166e,
    name: "catalog-id-products___en"
  }, {
    path: "/product/:id?/:slug",
    component: _4622bd22,
    name: "product-id-slug___en"
  }, {
    path: "/",
    component: _a292891a,
    name: "index___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config.app && config.app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
